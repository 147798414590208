import React from "react"
import { navigate, graphql } from "gatsby";
import { Layout } from "@components/Layout";
import {  Grid } from "semantic-ui-react";
import theme from "@styles/theme";
import { GenericBannerContainer } from "@components/GenericBannerContainer";
import { H2, ErrorContainer, H6, Paragraph2, Paragraph1 } from "@styles/Global.styles";
import { Record } from 'immutable';
import { connect } from 'react-redux'
import {DpsContainer} from '@styles/Global.styles';
import { currentBasketSelector } from "@redux/selectors/basketSelector";
import { BasketResponseModel } from "@models/Basket/BasketResponseModel";
import { Checkout } from "@models/Checkout/Checkout";
import { PaymentService } from "@services/PaymentService";
import { currentCheckoutSelector } from "@redux/selectors/checkoutSelector";

interface PaymentPageProps {
    location: any
    basket: BasketResponseModel,
    checkout: Checkout,
    data: any,
    clearBasket: Function
}
interface PaymentPageStates{
    retryCount: number,
    loading: boolean,
    paymentUrl: string,
}

const MAX_RETRY = 2;

class PaymentPageContainer extends React.Component<PaymentPageProps,PaymentPageStates>{

    constructor(props:PaymentPageProps){
        super(props);
        this.state = {
            retryCount: 0,
            loading: false,
            paymentUrl: this.props.location.state ? this.props.location.state.url : "",
        }
    }
    
    bindEvent = (element:any, eventName:any, eventHandler:any) => {
        if (element.addEventListener){
            element.addEventListener(eventName, eventHandler, false);
        } else if (element.attachEvent) {
            element.attachEvent('on' + eventName, eventHandler);
        }
    }

    componentDidMount(){
        window.addEventListener('message',this.handleReceiveMessage);
    }
    
    componentWillUnmount() {
      window.removeEventListener('message', this.handleReceiveMessage)
    }

    regeneratePaymentUrl = (apiUrl: string, basketId:string, paymentType:number, amount: number | undefined) =>{
        PaymentService.paymentOnlineRequest(apiUrl, basketId, paymentType, amount).then(response=>{
            if(response && response.url){
                this.setState({paymentUrl: response.url});
            }else{
                this.setState({paymentUrl: ""});
            }
        })
    }

    handleReceiveMessage = (e:any) =>{
        if(e.origin !== 'https://secure.livechatinc.com'){
            if(e.data){
                if(e.data.source && e.data.source.includes("-devtools-")){
                    //nothing
                }else{
                    if(e.data.status){
                        if(e.data.status ==='success'){
                            const transactionId = e.data.receiptNumber;
                            if(!this.props.location.state.isPaymentRequest){
                                navigate(`/order-confirmation/?transactionId=${transactionId}`);
                            }
                            else {
                                navigate(`/payment-success`);
                            }
                        }else{
                            console.log(e.data);
                            const errorMessage = e.data.errorMessage;
                            if(this.state.retryCount < MAX_RETRY && !this.props.location.state.isPaymentRequest){
                                this.setState({loading: true});
                                this.setState(prevState => {
                                    return {retryCount: prevState.retryCount + 1}
                                });
                                let amount: number | undefined = undefined;
                                if(this.props.checkout.isLayby){
                                    amount = this.props.checkout.laybyQuote.depositAmount;
                                }
                                this.regeneratePaymentUrl(this.props.data.site.siteMetadata.apiUrl,this.props.basket.basketId,this.props.checkout.paymentType, amount);
                                this.setState({loading: false});
                            }else{
                                navigate(`/payment-failure/?err=${errorMessage}`);
                            }
                        }
                    }
                }
            }
        }
    }

    render(){
        return (
        <Layout version='simple' currentStep={4} location={this.props.location}>   
            <GenericBannerContainer 
                padding={{
                    mobile: {
                        top: 10,
                        bottom: 40
                    },
                    desktop: {
                        top: 30,
                        bottom: 60
                    }
                  }}
                backgroundColor={theme.brand.colors.beige}>           
                    <Grid stackable reversed='mobile tablet vertically'>
                            <Grid.Column mobile={16} tablet={16} computer={16}>
                                <Grid>
                                    <Grid.Column width={16}>
                                        <H2>Payment</H2>
                                    </Grid.Column>
                                    {
                                        this.state.retryCount > 0 && 
                                        <Grid.Column width={16}>
                                            <ErrorContainer>
                                                <H6 color={theme.brand.colors.error}>Payment Failed</H6>
                                                <Paragraph2 color={theme.brand.colors.error}>
                                                Please re-enter a different card number or contact us on <a href="tel:0800650111">0800 650 111</a> to use a different payment method.
                                                </Paragraph2>
                                            </ErrorContainer>
                                        </Grid.Column>
                                    }
                                    {
                                        this.state.paymentUrl !== "" &&
                                        <Grid.Column width={16}>
                                            <DpsContainer src={this.state.paymentUrl} id="dps" name="dps" width="100%" height="620px"></DpsContainer>
                                            {
                                                !this.props.location.state.isPaymentRequest &&
                                                <Paragraph1 color={theme.brand.colors.darkGrey} textAlign="center">* Please remain on this page until payment is successful.</Paragraph1>
                                            }
                                        </Grid.Column>
                                    }
                                </Grid>
                            </Grid.Column>
                    </Grid>    
                
            </GenericBannerContainer>

        </Layout>);
    }
}

const mapStateToProps = (state: Record<any>) =>{
    const basket = currentBasketSelector(state).toJS();
    const checkout = currentCheckoutSelector(state).toJS();
    return {
        basket: basket,
        checkout: checkout
    };
}

const PaymentPage = connect(mapStateToProps)(PaymentPageContainer);

export default PaymentPage;


export const query = graphql`{
    site {
      siteMetadata {
        apiUrl
      }
    }
}`